<template lang="">
  <div>
    <div
      class="modal fade modalInvoice"
      :id="idModal"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="card-modal">
            <div class="modal-body mt-3">
              <div class="payment-invoice">
                <div class="row text-right">
                  <div class="col-md-12">
                    <button
                      type="button"
                      @click="exportPdf"
                      :disabled="loadingDownload"
                      class="btn btn-export"
                    >
                      <svg
                        width="24"
                        height="24"
                        v-if="!loadingDownload"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M11.2929 2.29289C11.6834 1.90237 12.3166 1.90237 12.7071 2.29289L16.7071 6.29289C17.0976 6.68342 17.0976 7.31658 16.7071 7.70711C16.3166 8.09763 15.6834 8.09763 15.2929 7.70711L13 5.41421V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V5.41421L8.70711 7.70711C8.31658 8.09763 7.68342 8.09763 7.29289 7.70711C6.90237 7.31658 6.90237 6.68342 7.29289 6.29289L11.2929 2.29289ZM5 17C5.55228 17 6 17.4477 6 18V20H18V18C18 17.4477 18.4477 17 19 17C19.5523 17 20 17.4477 20 18V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V18C4 17.4477 4.44772 17 5 17Z"
                          fill="#68CAF8"
                        />
                      </svg>
                      <i class="fas fa-spinner fa-spin" v-else></i>
                      Export PDF
                    </button>
                  </div>
                </div>
                <div id="area-print">
                  <div class="row mt-4 align-items-center">
                    <div class="col-md-6">
                      <div class="title-invoice">
                        <canvas id="imgCanvas" style="height: 45px;width: auto;"></canvas>
                      <!-- <img :src="logo_company" alt="company_logo" style="height: 45px;width: auto;"> -->
                        <!-- <img src="/img/logo.png" alt="logo.png" /> -->
                      </div>
                    </div>
                    <div
                      class="col-md-6 text-right address-company d-flex justify-content-end flex-wrap"
                    >
                      <div class="w-100" v-if="isProsses">
                        <Skeletor
                          height="30px"
                          class="rounded mb-2 float-right"
                          width="220px"
                        />
                        <div class="clearfix"></div>
                        <Skeletor
                          height="30px"
                          class="rounded mb-2 float-right clearfix"
                          width="180px"
                        />
                      </div>
                      <div style="max-width: 280px" v-else>
                        {{ data.alamat_company }}
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div class="card-detail-invoice">
                    <div class="row" v-if="isProsses">
                      <div class="col-md-6">
                        <div class="text">
                          <Skeletor
                            height="30px"
                            class="rounded mb-2"
                            width="200px"
                          />
                        </div>

                        <div class="text">
                          <Skeletor
                            height="30px"
                            class="rounded mb-2"
                            width="200px"
                          />
                        </div>
                        <div class="text">
                          <Skeletor
                            height="30px"
                            class="rounded mb-2"
                            width="200px"
                          />
                        </div>
                        <div class="text">
                          <Skeletor
                            height="30px"
                            class="rounded mb-2"
                            width="200px"
                          />
                        </div>
                      </div>
                      <div class="col-md-6 text-right">
                        <div class="text clearfix">
                          <Skeletor
                            height="30px"
                            class="rounded mb-2"
                            style="float: right"
                            width="200px"
                          />
                        </div>
                        <div class="text w-100 clearfix">
                          <Skeletor
                            height="30px"
                            class="rounded mb-2"
                            style="float: right"
                            width="230px"
                          />
                        </div>
                        <div class="text clearfix">
                          <Skeletor
                            height="30px"
                            class="rounded mb-2"
                            style="float: right"
                            width="190px"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row" v-else>
                      <div class="col-md-6">
                        <div class="text">Invoice</div>

                        <div class="text">#{{ data.no_invoice }}</div>
                        <div class="text">
                          Tanggal : {{ formatDate(data.tanggal) }}
                        </div>
                        <div class="text">
                          Due Date : {{ formatDate(data.due_date) }}
                        </div>

                        <div class="text text-capitalize">
                          Status : {{ data.status_pembayaran }}
                        </div>
                      </div>
                      <div class="col-md-6 text-right">
                        <div class="text">Kepada</div>
                        <div class="text w-100">
                          {{ data.client }}
                        </div>
                        <div class="text">
                          {{ data.alamat_client }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mt-4 detail-company">
                    <div class="col-md-12">
                      <table class="table table-invoice">
                        <thead>
                          <tr>
                            <th style="width: 20%">Nama Item</th>
                            <th style="width: 20%" class="text-right">Nilai</th>
                            <th style="width: 20%" class="text-center">QTY</th>
                            <!-- <th style="width:8%;"> Pajak</th> -->
                            <th style="width: 17%" class="text-right">
                              Jumlah
                            </th>
                          </tr>
                        </thead>
                        <tbody v-if="isProsses">
                          <tr>
                            <td colspan="5" class="py-2">
                              <Skeletor height="40px" class="mb-2 rounded" />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5" class="py-2">
                              <Skeletor height="40px" class="mb-2 rounded" />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="5" class="py-2">
                              <Skeletor height="40px" class="mb-2 rounded" />
                            </td>
                          </tr>
                        </tbody>
                        <tbody v-if="!isProsses">
                          <tr
                            v-for="(value, index) in data.detail"
                            :key="index"
                          >
                            <td>
                              <div class="invoice-cell">{{ value.nama }}</div>
                            </td>
                            <td class="text-right">
                              <div class="invoice-cell">
                                {{ formatMoney(value.nilai) }}
                              </div>
                            </td>
                            <td class="text-center">
                              <div class="invoice-cell text-center">
                                {{ value.qty }}
                              </div>
                            </td>
                            <!-- <td class="text-center">
                                                        <div class="invoice-cell">{{(value.pajak) ? value.pajak : 0}}%</div>
                                                    </td> -->
                            <td class="text-right">
                              <div class="invoice-cell">
                                {{ formatMoney(value.sub_total) }}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tfoot v-if="!isProsses">
                          <tr>
                            <td colspan="2" rowspan="3">
                              <div style="font-size: 14px; font-weight: normal; padding: 15px">
                                Transfer ke Rekening {{ data.bank.bank }} - <i><b>{{ data.bank.nomor_rekening }} A.N {{ data.bank.atas_nama }}</b></i>
                              </div>
                            </td>
                            <td style="border-bottom: 1px solid #dde2e6">
                              Subtotal
                            </td>
                            <td
                              style="border-bottom: 1px solid #dde2e6"
                              class="text-right"
                            >
                              {{
                                data.sub_total ? formatMoney(data.sub_total) : 0
                              }}
                            </td>
                          </tr>
                          <tr>
                            <!-- <td colspan="2" class="border-0">&nbsp;</td> -->
                            <td class="border-0 d-flex">
                              <span>Pajak</span>
                              <!-- <span class="d-inline-block ml-2"
                                >{{ data.pajak ? data.pajak : 0 }}%</span
                              > -->
                            </td>
                            <td class="text-right border-0">
                              {{
                                data.nilai_pajak
                                  ? formatMoney(data.nilai_pajak)
                                  : 0
                              }}
                            </td>
                          </tr>
                          <!-- <tr>
                            <td colspan="2">&nbsp;</td>
                            <td class="border-2">Subtotal</td>
                            <td class="text-right border-2">
                              {{ formatMoney(data.nilai) }}
                            </td>
                          </tr>

                          <tr>
                            <td colspan="2" style="border: none">&nbsp;</td>
                            <td style="border: none">
                              <div class="d-flex align-items-center">
                                <div class="mr-5">Pajak</div>
                                <div class="input-group">
                                  <input
                                    type="text"
                                    class="form-control text-right"
                                    id="inlineFormInputGroupUsername"
                                  />
                                  <div class="input-group-prepend">
                                    <div class="input-group-text">%</div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td class="text-right" style="border: none">
                              {{ formatMoney(data.nilai) }}
                            </td>
                          </tr> -->

                          <tr>
                            <!-- <td colspan="2" style="border-top: 0">&nbsp;</td> -->
                            <td class="border-2" style="border-bottom: 1px solid #dee2e6">Total</td>
                            <td class="text-right border-2" style="border-bottom: 1px solid #dee2e6">
                              <b>{{ formatMoney(data.nilai) }}</b>
                            </td>
                          </tr>
                          <tr v-if="data.sisa_pembayaran > 0 && data.sisa_pembayaran">
                            <td colspan="2" style="border-top: 0">&nbsp;</td>
                            <td class="border-2">Sisa Pembayaran</td>
                            <td class="text-right border-2 text-danger">
                              <b>{{ formatMoney(data.sisa_pembayaran) }}</b>
                            </td>
                          </tr>
                          <tr v-if="data.signing_person && data.signing_person.length > 0">
                            <td colspan="4" class="border-0">
                              <div class="row mt-4" :class="{ 'justify-content-center': data.signing_person.length > 1, 'justify-content-end': data.signing_person.length == 1  }">
                                <div class="col-4 text-center mb-2" style="padding: 0 20px" v-for="(value, index) in data.signing_person" :key="index">
                                  {{ value.nama_jabatan }}
                                  <br>
                                  <br>
                                  <br>
                                  <br>
                                  <div style="border-top: 1px solid black; padding-top: 3px">{{ value.nama_karyawan }}</div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="2" class="border-0"></td>
                            <td colspan="2" class="px-0 border-0">
                              <div class="d-flex justify-content-end">
                                <button
                                  class="btn btn-add w-50 py-2"
                                  v-if="
                                    (data.status_pembayaran == 'unpaid' || data.status_pembayaran == 'partially paid') &&
                                    !loadingDownload
                                  "
                                  type="button"
                                  @click="toPembayaran"
                                  style="font-size: 15px; padding: 8px 18px"
                                >
                                  Bayar
                                </button>
                                <button
                                  class="btn btn-add w-50 ml-2"
                                  v-if="
                                    (data.status_pembayaran == 'unpaid') &&
                                    !loadingDownload
                                  "
                                  type="button"
                                  @click="toCancel"
                                  style="
                                    font-size: 15px;
                                    padding: 10px 18px;
                                    background: #ff3d3d !important;
                                  "
                                >
                                  Batal
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  get_PiutangInvoiceCancel,
  get_PiutangInvoiceGet,
} from "../../../actions/piutang";
import $ from "jquery";
import { v4 as uuid4 } from "uuid";
import { Skeletor } from "vue-skeletor";
import moment from "moment";
import "moment/locale/id";
import {
  get_HutangInvoiceGet,
  get_HutangInvoiceCancel,
} from "../../../actions/hutang";
import html2pdf from "html2pdf.js/dist/html2pdf.bundle.min.js";
import { showAlert, cksClient } from "../../../helper";
import { DATA } from "../../../constans/Url"  
export default {
  name: "InvoiceComponent",
  props: {
    show: Boolean,
    type: String,
    noInvoice: String,
    kode_project: String,
  },
  components: {
    Skeletor,
  },
  data() {
    return {
      logo_company: '',
      isProsses: false,
      idModal: uuid4(),
      kodeProject: this.$props.kode_project ? this.$props.kode_project : "",
      data: {
        no_invoice: "",
        tanggal: "",
        due_date: "",
        nilai: "",
        client: "",
        alamat_company: "",
        alamat_client: "",
        detail: "",
        pajak: 0,
        sisa_pembayaran: 0,
        nilai_pajak: 0,
        signing_person: null,
        bank: {
          bank: '',
          atas_nama: '',
          nomor_rekening: ''
        }
      },
      typeInvoice: this.$props.type,
      loadingDownload: false,
    };
  },
  mounted() {
    $(`#${this.idModal}`).on("hidden.bs.modal", () => {
      this.$emit("hide");
    });
    this.toBlobUrl(DATA.COMPANY(cksClient().get("_account").company_logo));
    // this.showModal()
  },
  methods: {
    async toBlobUrl(url) {
      // var urld  = await fetch(url).then(r => r.blob());
      // var urlblob = window.URL.createObjectURL(new Blob([urld]));
      // this.logo_company = urlblob;
      var canvas = document.getElementById('imgCanvas');
      var img = new Image();
      // Setting cross origin value to anonymous
      img.crossOrigin = 'anonymous'
      // The Image URL is been set to the
      // src property of the image
      img.src = url
      // This function waits until the image being loaded.
      img.onload = function () {
          // Matches the canvas width to that of the image
          canvas.width = this.width
          // Matches the canvas height to that of the image
          canvas.height = this.height
          // Displays the image to the canvas tag of id Canvas
          canvas.getContext('2d').drawImage(this, 0, 0)
      }
      
      // return callback(urlblob);
    },
    async exportPdf() {
      this.loadingDownload = true;
      var element = document.getElementById("area-print");
      var opt = {
        margin: [0.2, 0.6, 0.6, 0.6],
        filename: "invoice.pdf",
        image: { type: "jpeg", quality: 0.95 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };
      await html2pdf().set(opt).from(element).save();
      setTimeout(() => {
        this.loadingDownload = false;
      }, 500);
    },
    getData() {
      this.isProsses = true;
      if (this.typeInvoice == "piutang" || this.typeInvoice == "project") {
        get_PiutangInvoiceGet(this.$props.noInvoice, (res) => {
          this.isProsses = false;
          const { data, detail } = res;
          this.data = data;
          this.data.sisa_pembayaran += parseInt(data.sisa_pajak);
          console.log(this.data.sisa_pembayaran);
          this.data.detail = detail;
        }, (err) => {
          console.log(err)
        });
      } else if (this.typeInvoice === "hutang") {
        get_HutangInvoiceGet(this.$props.noInvoice, (res) => {
          this.isProsses = false;
          const { data, detail } = res;
          this.data = data;
          this.data.no_invoice = data.no_po;
          this.data.detail = detail;
        });
      }
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },
    showModal() {
      $(`#${this.idModal}`).modal("show");
    },
    hideModal() {
      $(`#${this.idModal}`).modal("hide");
    },
    toPembayaran() {
      this.hideModal();
      if (this.$props.type === "hutang") {
        this.$router.push({
          name: "PaymentInovice",
          params: {
            id: this.data.no_invoice,
          },
        });
      } else if (this.$props.type === "piutang") {
        this.$router.push({
          name: "PayemntInvoiceAccountReceivable",
          params: {
            id: this.data.no_invoice,
          },
        });
      } else if (this.$props.type === "project") {
        this.$router.push({
          name: "PaymentInvoiceProject",
          params: {
            kode_project: this.kodeProject,
            id: this.data.no_invoice,
          },
        });
      }
    },
    toCancel() {
      if (this.$props.type === "piutang" || this.$props.type === "project") {
        $(`#${this.idModal}`).modal("hide");
        this.$emit("hide");
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin akan membatalkan invoice ini?",
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Tidak",
          confirmButtonText: "Ya, Batalkan",
          onSubmit: () => {
            get_PiutangInvoiceCancel(
              {
                no_invoice: this.data.no_invoice,
                type: this.typeInvoice,
              },
              () => {
                this.data.status_pembayaran
                  ? (this.data.status_pembayaran = "cancel")
                  : "";
                this.$emit("reload");
              }
            );
          },
        });
      } else if (this.$props.type === "hutang") {
        this.$emit("hide");
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin akan membatalkan invoice ini?",
          showCancelButton: true,
          showConfirmButton: true,
          cancelButtonText: "Tidak",
          confirmButtonText: "Ya, Batalkan",
          onSubmit: () => {
            get_HutangInvoiceCancel(this.data.no_invoice, () => {
              this.data.status_pembayaran
                ? (this.data.status_pembayaran = "cancel")
                : "";
              this.$emit("reload");
            });
          },
        });
      }
    },
  },
  watch: {
    show: function (newVal) {
      if (newVal) {
        this.showModal();
      } else {
        this.hideModal();
      }
    },
    noInvoice: function () {
      this.getData();
    },
    kode_project: function (newVal) {
      this.kodeProject = newVal;
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.btn-lampiran {
  background-color: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
}

#modalAddPayment .btn-save {
  background-color: transparent !important;
  color: #68caf8 !important;
  border: 1px solid #68caf8 !important;
  font-weight: 400;
  font-size: 15px;
  padding: 10px 0px;
}

#modalAddPayment .btn-save:hover {
  background-color: #68caf8 !important;
  color: #fff !important;
}

.card-detail-invoice {
  background: linear-gradient(103.2deg, #6ec0fe 0%, #4df3dd 100%);
  border-radius: 5px;
  color: #fff;
  padding: 10px 20px;
  line-height: 30px;
}

.modalInvoice .modal-dialog {
  max-width: 1162px;
}

.btn-add-payment {
  background-color: #d7ffea;
  color: #3cb778;
  padding: 5px 10px;
  font-weight: 500;
  margin-right: 15px;
}

.btn-print-invoice {
  background-color: #f9ffd7;
  color: #b9b201;
  padding: 5px 10px;
  font-weight: 500;
}

.address-company {
  font-size: 12px;
  color: #263238;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.noEstimated {
  font-size: 12px;
  color: #898989;
}

.form-label {
  max-width: 134px;
  width: 200px;
}

.card-total {
  display: flex;
}

.card {
  border: 1px solid #eaeaea;
  border-radius: 10px;
}

.payment-invoice {
  padding: 0px 46px;
}

.sub-total {
  margin-right: 50px;
  font-size: 14px;
}

.amount {
  font-size: 14px;
}

.name-company {
  font-size: 18px;
  font-weight: 500;
}

.detail-company .value {
  color: #263238;
  font-size: 16px;
  font-weight: 400;
}

.detail-company .text {
  color: #cdcdcd;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.title-invoice {
  font-weight: 700;
  font-size: 50px;
  /* background: linear-gradient(102.18deg, #6EC0FE -17.12%, #61EFE7 95.5%); */
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.total-payment {
  font-size: 24px;
  font-weight: 600;
  margin-top: 10px;
}

.status-invoice-paid {
  background: #d7ffea;
  color: #3cb778;
  font-size: 20px;
  font-weight: 500;
  padding: 10px 50px;
  border: none;
  cursor: default;
  border-radius: 5px;
}

.btn-export {
  background: #e4efff;
  color: #68caf8;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 10px;
  border-radius: 5px;
  border: none;
  margin-right: 0px;
}

.modal .btn-print-invoice {
  background: #f9ffd7;
  color: #b9b201;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 23px;
  border-radius: 5px;
  border: none;
}

.btn-invoice {
  background: #fff3c7;
  color: #f8ce3a;
  font-size: 14px;
  font-weight: 500;
  padding: 10px 15px;
}

.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
.table.table-invoice td {
  vertical-align: middle;
  color: #263238;
}
.table.table-invoice td th {
  border-top: unset !important;
  border-bottom: 1px solid #dee2e6 !important;
}
.table.table-invoice thead th {
  color: #898989 !important;
  padding: 9px 16px;
}
.table.table-invoice tbody td {
  padding: 0px 0;
}
.table.table-invoice tbody td .invoice-cell {
  font-size: 15px;
  /* background: #eceff1; */
  padding: 10px 15px;
  font-weight: 500;
}
.table.table-invoice tfoot td {
  color: #263238;
  font-size: 15px;
  font-weight: 500;
}
.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}
</style>
