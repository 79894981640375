
import { API } from "../../constans/Url"
import { apiClient } from "../../helper"


const get_PiutangList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PIUTANG.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PiutangDetail = (id_piutang, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PIUTANG.GET, {id_piutang: id_piutang}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_PiutangSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PIUTANG.SAVE, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PiutangPreInvoice = (id_piutang, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PIUTANG.PRE_INVOICE, {id_piutang: id_piutang}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_PiutangSaveInvoice = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PIUTANG.SAVE_INVOICE, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_PiutangSavePembayaran = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PIUTANG.SAVE_PEMBAYARAN, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PiutangInvoiceGet = (no_invoice, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PIUTANG.GET_INVOICE, {no_invoice: no_invoice}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PiutangInvoiceCancel = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PIUTANG.CANCEL_INVOICE, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_PiutangJurnalBack = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PIUTANG.JURNAL_BACK, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_PiutangList,
  get_PiutangDetail,
  post_PiutangSave,
  post_PiutangSaveInvoice,
  post_PiutangSavePembayaran,
  get_PiutangPreInvoice,
  get_PiutangInvoiceGet,
  get_PiutangInvoiceCancel,
  post_PiutangJurnalBack
}